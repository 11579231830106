
<template>
  <div>
    <super-header />

    <h1><router-link class="action-btn" to="/super/categories">Categories</router-link></h1>
    <h1><router-link class="action-btn" to="/super/directories">Directories</router-link></h1>

    <!-- <div><router-link class="action-btn" to="/super/listings/create">Create New Listing</router-link></div> -->
    <!-- <div><a class="action-btn" :href="ENV_URL + '/super/listings/csv'" target="_blank">Export Listings to CSV</a></div> -->
  </div>
</template>
<script>
import SuperHeader from 'components/super/SuperHeader.vue';
import {ENV_URL} from '../../environment.js';
import axios from 'axios';
export default {
  name: 'directory-view',

  components: {
    SuperHeader,
  },
  data () {
    return {
      ENV_URL: ENV_URL
    }

  },
  created() {

  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  mounted() {

  },

  methods: {
    formSubmitted() {
    },
  },
}
</script>

<style lang="scss">

</style>
